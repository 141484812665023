const mycalendar = (theme) => {
	const eventColor = '#3174ad';
	const meetingColor = '#73b374';
	const followupColor = '#7373f2';
	const activityColor = '#f2c573';
	const appointmentColor = '#f27374';

	return {
		// '.MuiLink-button': {
		// 	border: 'unset',
		// 	padding: '6px 16px',
		// 	backgroundColor: '#e0e0e0',
		// },
		'.mycalendar-newevent-button-close': {
			float: 'right',
		},
		'.date-in-past': {
			width: '14.3%',
			background: '#ccc',
			borderRight: 'solid 1px #fff',
		},
		'.special-day': {
			width: '14.3%',
			background: 'bisque',
			borderRight: 'solid 1px #fff',
		},
		'.unavailable-class': {
			backgroundColor: '#e6e6e6',
		},
		'.rbc-event': {
			backgroundColor: eventColor,
		},
		'.rbc-event.rbc-selected': {
			backgroundColor: eventColor,
			filter: 'brightness(85%)',
		},
		'.calendar-toolbar-grid-item': {
			display: 'flex',
		},
		'.calendar-toolbar-grid-item-label': {
			justifyContent: 'space-evenly',
		},
		'.calendar-toolbar-grid-item-buttongroup': {
			justifyContent: 'flex-end',
		},
		'.rbc-time-header-content > .rbc-row.rbc-row-resource': {
			borderBottom: '1px solid ' + theme.palette.grey[400],
			backgroundColor: theme.palette.grey[100],
		},
		'.availability-calendar-button': {
			marginLeft: theme.spacing(2),
		},
		'.rbc-allday-cell': {
			display: 'none',
		},
		'.rbc-time-view .rbc-header': {
			borderBottom: 'none',
		},
		'.calendar-event-preview': {
			backgroundColor: 'red',
			border: '1px solid ' + theme.palette.grey[400] + '!important',
			opacity: '50%',
		},
		'div.calendar-event-appointment': {
			backgroundColor: appointmentColor,
			border: '1px solid ' + theme.palette.grey[400] + '!important',
		},
		'.rbc-event.rbc-selected.calendar-event-appointment': {
			backgroundColor: appointmentColor,
			filter: 'brightness(85%)',
		},
		'div.calendar-event-followup': {
			backgroundColor: followupColor,
			border: '1px solid ' + theme.palette.grey[400] + '!important',
		},

		'.rbc-event.calendar-event-followup.rbc-selected': {
			backgroundColor: followupColor,
			filter: 'brightness(85%)',
		},
		'div.calendar-event-meetings': {
			backgroundColor: meetingColor,
			border: '1px solid ' + theme.palette.grey[400] + '!important',
		},
		'div.calendar-event-activity': {
			backgroundColor: activityColor,
			border: '1px solid ' + theme.palette.grey[400] + '!important',
		},
		'.rbc-event.calendar-event-activity.rbc-selected': {
			backgroundColor: activityColor,
			filter: 'brightness(85%)',
		},
		'.rbc-event.calendar-event-meetings.rbc-selected': {
			backgroundColor: meetingColor,
			filter: 'brightness(85%)',
		},
		'.rbc-agenda-view': {
			// fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		},
		'.appointment-toolbar-grid-container': {
			marginBottom: theme.spacing(1),
		},

		'.appointment-toolbar-grid-item': {
			display: 'flex',
			[theme.breakpoints.down('md')]: {
				display: 'block',
				textAlign: 'center',
			},
		},
		'.appointment-toolbar-grid-item-label': {
			justifyContent: 'flex-end',
			[theme.breakpoints.down('md')]: {
				display: 'block',
				textAlign: 'center',
			},
		},
		'.calendar-box-max-width': {
			width: '100%',
		},
		'.calendar-event-legend-event': {
			color: eventColor,
		},
		'.calendar-event-legend-appointment': {
			color: appointmentColor,
		},
		'.calendar-event-legend-followup': {
			color: followupColor,
		},
		'.calendar-event-legend-meeting': {
			color: meetingColor,
		},
		'.calendar-event-legend-activity': {
			color: activityColor,
		},
		'.calendar-button-activity': {
			marginTop: theme.spacing(2),
		},
		'.event-form-info': {
			marginBottom: theme.spacing(2),
		},
		'.event-modal-capitalize': {
			textTransform: 'capitalize',
		},
		/** Agenda stacked events */
		'.calendar-agenda-stacked-event': {
			marginBottom: theme.spacing(2),
		},
		'.calendar-agenda-stacked-event-event': {
			background: 'linear-gradient(to top right,transparent 50%,' + eventColor + ' 0) top right/40px 40px no-repeat, white',
		},
		'.calendar-agenda-stacked-event-appointment': {
			background: 'linear-gradient(to top right,transparent 50%,' + appointmentColor + ' 0) top right/40px 40px no-repeat, white',
		},
		'.calendar-agenda-stacked-event-followup': {
			background: 'linear-gradient(to top right,transparent 50%,' + followupColor + ' 0) top right/40px 40px no-repeat, white',
		},
		'.calendar-agenda-stacked-event-meetings': {
			background: 'linear-gradient(to top right,transparent 50%,' + meetingColor + ' 0) top right/40px 40px no-repeat, white',
		},
		'.calendar-agenda-stacked-event-activity': {
			background: 'linear-gradient(to top right,transparent 50%,' + activityColor + ' 0) top right/40px 40px no-repeat, white',
		},
		/** Legend Agenda widget*/
		'.calendar-agenda-stacked-event-legend-event': {
			background: 'linear-gradient(to top right,transparent 50%,' + eventColor + ' 0) top right/27px 27px no-repeat, white',
			width: '30px',
			height: '30px',
			marginRight: theme.spacing(1),
		},
		'.calendar-agenda-stacked-event-legend-appointment': {
			background: 'linear-gradient(to top right,transparent 50%,' + appointmentColor + ' 0) top right/27px 27px no-repeat, white',
			width: '30px',
			height: '30px',
			marginRight: theme.spacing(1),
		},
		'.calendar-agenda-stacked-event-legend-followup': {
			background: 'linear-gradient(to top right,transparent 50%,' + followupColor + ' 0) top right/27px 27px no-repeat, white',
			width: '30px',
			height: '30px',
			marginRight: theme.spacing(1),
		},
		'.calendar-agenda-stacked-event-legend-meetings': {
			background: 'linear-gradient(to top right,transparent 50%,' + meetingColor + ' 0) top right/27px 27px no-repeat, white',
			width: '30px',
			height: '30px',
			marginRight: theme.spacing(1),
		},
		'.calendar-agenda-stacked-event-legend-activity': {
			background: 'linear-gradient(to top right,transparent 50%,' + activityColor + ' 0) top right/27px 27px no-repeat, white',
			width: '30px',
			height: '30px',
			marginRight: theme.spacing(1),
		},
	};
};

export default mycalendar;
