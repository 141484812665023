import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Typography, Link } from '@mui/material';

function MadeBy({ translate, ...props }) {
	return (
		<Typography
			variant="body2"
			color="textSecondary"
			align="center"
			{...(props.platform === 'backoffice' ? { className: 'login-backoffice-language-selector' } : {})}
		>
			{translate('login_madeby', {
				who: (
					<Link color="inherit" target="_blank" href="https://altericare.se">
						{translate('company_name')}
					</Link>
				),
			})}
		</Typography>
	);
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	platform: state.login.platform,
});

export default connect(mapStateToProps)(MadeBy);
