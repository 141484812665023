import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import Loading from '../Loading';

function RenderInputAdornment({ input, meta: { invalid, touched, error, active }, ...custom }) {
	if (typeof custom.inputdata.onValidValue !== 'undefined' && !invalid && input.value !== '') {
		custom.inputdata.onValidValue();
	}

	return (
		<TextField
			{...input}
			id={custom.inputdata.id}
			name={custom.inputdata.name}
			label={custom.inputdata.label}
			placeholder={custom.inputdata.placeholder}
			fullWidth
			variant="outlined"
			disabled={custom.inputdata.readonly}
			error={touched && invalid && !active}
			helperText={touched && !active && error}
			onChange={(event) => {
				input.onChange(event);
				if (typeof custom.inputdata.onChange !== 'undefined') {
					custom.inputdata.onChange(event.target.value);
				}
			}}
			type={custom.inputdata.type}
			{...custom}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						{custom.inputdata.adornment.triggered ? (
							<Loading button={true} insideComponent={true} size={18} />
						) : (
								<IconButton
                  color={invalid ? 'inherit' : 'primary'}
                  disabled={!invalid || custom.inputdata.adornment.alwaysEnabled ? false : true}
                  onClick={(e) => {
                    custom.inputdata.adornment.action(e);
									}}
                  aria-label="toggle password visibility"
                  edge="end"
                  size="large"
                >
									  {custom.inputdata.adornment.icon ? custom.inputdata.adornment.icon : <SearchIcon />}
								</IconButton>
							)}
					</InputAdornment>
				),
			}}
		/>
	);
}

export default RenderInputAdornment;
