const precall = (theme) => {
	return {
		'.precall-main-container': {
			backgroundColor: theme.palette.grey[900],
		},
		'.precall-title': {
			color: theme.palette.video.contrastText,
		},
		'.precall-description': {
			color: theme.palette.secondary.contrastText,
		},
		'.pre-call-publisher-container': {
			height: '300px',
			display: 'flex',
			alignItems: 'center',
		},
		'.precall-publisher-error-grid-container': {
			border: '1px solid ' + theme.palette.error.main,
		},
		'.precall-publisher-error-description': {
			color: theme.palette.error.main,
		},
		'.precall-button': {
			color: theme.palette.common.white,
		},
		'.precall-button.Mui-disabled': {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.primary.main,
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
			},
			// borderColor: theme.palette.grey[200],
		},
		'.precall-network-test-results-box .MuiTypography-root': {
			color: theme.palette.common.white,
		},
		'.precall-box-control-container > svg': {
			color: theme.palette.grey[300],
		},
		'.precall-box-control-container .MuiInputBase-root': {
			color: theme.palette.common.white,
		},
		'.precall-box-control-container fieldset': {
			borderColor: theme.palette.grey[300] + ' !important',
		},
		'.precall-box-control-container label': {
			color: theme.palette.common.white,
		},
		'.precall-box-control-container button': {
			color: theme.palette.common.white,
		},
		'.precall-box-control-container .Mui-disabled': {
			border: 'none',
		},
		'.precall-box-control-container .MuiButtonBase-root': {
			color: theme.palette.grey[300],
		},
		'.precall-box-control-container .Mui-disabled': {
			color: theme.palette.grey[500],
		},
		'.precall-box-control-meter-container fieldset': {
			borderColor: theme.palette.grey[300] + ' !important',
		},
		'.precall-box-control-meter-container.Mui-disabled fieldset': {
			borderColor: theme.palette.grey[500],
		},
		'.precall-box-control-meter-container label': {
			color: theme.palette.common.white,
		},
		'.precall-box-control-meter-container .MuiTypography-root': {
			color: theme.palette.common.white,
		},
		'.precall-box-control-meter-container .MuiInputBase-root': {
			color: theme.palette.common.white,
		},
		'.precall-box-control-meter-container .Mui-disabled': {
			color: theme.palette.grey[500],
		},
		'.precall-icon': {
			color: theme.palette.common.white,
		},
		'.precall-camera-preview-container': {
			marginBottom: theme.spacing(0.5),
		},
		'.precall-box-control-container': {
			display: 'flex',
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		'.precall-box-control-meter-container': {
			position: 'relative',
			top: '50%',
			transform: 'translateY(-50%)',
			textAlign: 'center',
		},
		'.precall-volume-meter': {
			height: theme.spacing(2),
		},
		'.precall-network-test-speed': {
			fontWeight: 'bold',
		},
		'.precall-network-test-results-box': {
			textAlign: 'center',
		},
		'.precall-divider': {
			backgroundColor: theme.palette.grey[300],
			marginTop: theme.spacing(0.5),
			marginBottom: theme.spacing(0.5),
		},
		'.precall-device-settings-button': {
			textAlign: 'center',
			color: theme.palette.common.white,
		},
		'.precall-box-control-error-container': {
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		'.precall-publisher-alert-error': {
			color: theme.palette.common.white,
			border: 'none',
		},
	};
};

export default precall;
