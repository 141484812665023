import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTranslate, getLanguages, getActiveLanguage, setActiveLanguage } from 'react-localize-redux';
import { Container, Typography, Grid, Box, Link, Paper, TextField, MenuItem } from '@mui/material';
import { Alert } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Loading from '../Generic/Loading';
import MessageToastLogin from '../Generic/MessageToastLogin';
import { getMeetingPublicAccessAction, getMeetingAccessLoginAction } from '../Meetings/actions/meetingsActions';
import Form from '../Generic/Form';
import { formValueSelector } from 'redux-form';
import { isMobileSize } from '../../helpers/functions/functions';
import MeetingLoginTabs from './MeetingLoginTabs';
import PlaceholderIMG from '../../assets/images/placeholder.svg';
import { push } from 'connected-react-router';
import MadeBy from '../Login/MadeBy';
import LoginGuest from '../Login/LoginGuest';

class MeetingLogin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading_login_tabs: true,
		};
	}

	componentDidMount() {
		if (this.props.match.params.token) {
			this.props.getMeetingPublicAccessAction(this.props.match.params.token, () => {
				this.setState({ loading_login_tabs: false });
			});
		} else {
			this.setState({ loading_login_tabs: false });
		}
	}

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	handleConfigMeetingTokenForm = () => {
		const { translate } = this.props;

		const structure = {
			form: 'meeting_public_token',
			title: '',
			subtitle: '',
			helperText: '',
			fields_grid_layout: {
				xs: 12,
				md: 12,
			},
			buttons_grid_layout: {
				xs: 12,
				md: 12,
				content_align: 'center',
			},
			maxWidth: 'lg',
			fields: [
				{
					id: 'token',
					name: 'token',
					label: translate('meeting_public_form_token'),
					type: 'input',
					validate: {
						required: true,
					},
					// grid_layout: { xs: 12, md: 6 },
					initialvalue: null,
				},
			],
			buttons: [
				{
					id: 'meeting_public_token_form_button_find',
					label: translate('meeting_public_token_form_button_find'),
					fullWidth: false,
					color: 'primary',
					action: this.getMeeting,
				},
			],
		};

		let initialValues = {};

		return { structure: structure, initialValues: initialValues };
	};

	/**TODO: add ssn validation
	 * add scope to external user role
	 * return login props on meeting login
	 */
	handleConfigMeetingUserForm = () => {
		const { translate } = this.props;

		const structure = {
			form: 'meeting_public_user_info',
			title: this.props.meeting.login_type == 'internal' ? '' : translate('meeting_login_optional_form_title'),
			subtitle: '',
			helperText: '',
			maxWidth: 'lg',
			fields: [
				{
					id: 'ssn',
					name: 'ssn',
					label: translate('meeting_public_form_ssn'),
					type: this.props.meeting.login_type == 'internal' ? 'hidden' : 'input',
					grid_layout: { xs: 12, md: 6, lg: 12 },
					initialvalue: typeof this.props.meeting.invitee.ssn !== 'undefined' ? this.props.meeting.invitee.ssn : '',
					readonly:
						this.props.meeting.login_type == 'external' &&
						typeof this.props.meeting.invitee.ssn !== 'undefined' &&
						this.props.meeting.invitee.ssn !== null &&
						this.props.meeting.invitee.ssn.length > 0,
				},
				{
					id: 'name',
					name: 'name',
					label: translate('meeting_public_form_name'),
					type: this.props.meeting.login_type == 'internal' ? 'hidden' : 'input',
					grid_layout: { xs: 12, md: 6, lg: 12 },
					initialvalue: typeof this.props.meeting.invitee.name !== 'undefined' ? this.props.meeting.invitee.name : '',
					readonly:
						this.props.meeting.login_type == 'external' &&
						typeof this.props.meeting.invitee.name !== 'undefined' &&
						this.props.meeting.invitee.name !== null &&
						this.props.meeting.invitee.name.length > 0,
				},
				{
					id: 'phone',
					name: 'phone',
					label: translate('meeting_public_form_phone'),
					type: this.props.meeting.login_type == 'internal' ? 'hidden' : 'number',
					disableNumberArrows: true,
					grid_layout: { xs: 12, md: 6, lg: 12 },
					initialvalue: typeof this.props.meeting.invitee.phone !== 'undefined' ? this.props.meeting.invitee.phone : '',
					readonly:
						this.props.meeting.login_type == 'external' &&
						typeof this.props.meeting.invitee.phone !== 'undefined' &&
						this.props.meeting.invitee.phone !== null &&
						this.props.meeting.invitee.phone.length > 0,
				},
				{
					id: 'email',
					name: 'email',
					label: translate('meeting_public_form_email'),
					type: this.props.meeting.login_type == 'internal' ? 'hidden' : 'input',
					validate: {
						email: true,
					},
					grid_layout: { xs: 12, md: 6, lg: 12 },
					initialvalue: typeof this.props.meeting.invitee.email !== 'undefined' ? this.props.meeting.invitee.email : '',
					readonly:
						this.props.meeting.login_type == 'external' &&
						typeof this.props.meeting.invitee.email !== 'undefined' &&
						this.props.meeting.invitee.email !== null &&
						this.props.meeting.invitee.email.length > 0,
				},
			],
		};

		let initialValues = this.handleDefaultValues(structure);

		return { structure: structure, initialValues: initialValues };
	};

	handleChangeLanguage = (value) => {
		this.props.setActiveLanguage(value);
	};

	getMeeting = () => {
		this.props.getMeetingPublicAccessAction(this.props.token_field, () => {
			this.props.push('/meetings/' + this.props.meeting.token);
		});
	};

	getMeetingLogin = (form) => {
		this.props.getMeetingAccessLoginAction('meeting_public_user_info', this.props.meeting.token, form);
	};

	render() {
		const { translate } = this.props;

		let configuration = {};
		if (!this.props.meeting.error) {
			configuration = this.handleConfigMeetingUserForm();
		} else {
			configuration = this.handleConfigMeetingTokenForm();
		}

		return (
			<Grid container component="main" className="meeting-login-main">
				<MessageToastLogin />
				<Grid item xs={12} lg={7} className="meeting-login-content-left-image">
					<Box height="100%" className="login-content-left-title" p={1} href="/">
						<Link component={RouterLink} to={'/'} color="inherit" underline="none">
							<img
								src={
									typeof this.props.settings.site !== 'undefined' &&
									this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image']
										? this.props.config.apihost +
										  '/configuration/files/' +
										  this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image'].uuid
										: PlaceholderIMG
								}
								alt="Login logo"
								style={{ width: '70%' }}
							/>
						</Link>
					</Box>
				</Grid>
				<Grid item xs={12} lg={5} component={Paper} elevation={6} square>
					<Grid container className="login-content-right-main">
						<Grid item xs={12}>
							<Container component="main" maxWidth={'lg'} className="generic-defaultlayout-content">
								<Typography className="meeting-public-title" align="center" variant="h2" component="h2" gutterBottom>
									{translate('meeting_login_title')}
								</Typography>
								{this.props.meeting.token !== null ? (
									<Typography align="justify" variant="body1" component="p" gutterBottom className="meeting-description">
										{translate('meeting_login_subtitle_' + this.props.meeting.login_type)}
									</Typography>
								) : (
									<Typography align="justify" variant="body1" component="p" gutterBottom className="meeting-description">
										{translate('meeting_login_subtitle_no_token')}
									</Typography>
								)}
								{this.state.loading_login_tabs ? (
									<Loading insideComponent={true} />
								) : (
									<React.Fragment>
										{((this.props.meeting.login_type !== 'internal' && this.props.settings.feature.meetings_guest_login) ||
											this.props.meeting.error) && (
											<Form
												hideButton={!this.props.meeting.error}
												form={configuration.structure.form}
												formData={configuration.structure}
												initialValues={configuration.initialValues}
											/>
										)}
										{!this.props.meeting.error && (
											<Container component="main" maxWidth={'md'} className="meeting-login-tabs-content">
												{this.props.settings.feature.meetings_guest_login && this.props.meeting.login_type !== 'internal' && (
													<LoginGuest
														selectedLanguage={this.props.selectedLanguage}
														handleSubmitAction={this.getMeetingLogin}
													/>
												)}
												<Typography variant="h5" gutterBottom align="center">
													{translate('meeting_login_tabs_title')}
												</Typography>
												{this.props.login.message && this.props.login.message.length > 0 && (
													<Box mb={2}>
														<Alert severity="warning">{this.props.login.message}</Alert>
													</Box>
												)}
												<MeetingLoginTabs
													formUserInfo="meeting_public_user_info"
													formUserLanguage="meeting_public_user_language"
												/>
											</Container>
										)}
									</React.Fragment>
								)}
							</Container>
						</Grid>
						<Grid item xs={12} className="login-content-right-footer">
							<div className={this.props.platform === 'backoffice' ? '' : 'login-content-right-footer'}>
								{this.props.languages.length > 1 && (
									<TextField
										select
										label={translate('generic_select')}
										value={this.props.currentLanguage ? this.props.currentLanguage.code : ''}
										onChange={(value) => this.handleChangeLanguage(value.target.value)}
										helperText={translate('login_select_preferred_language')}
										margin="normal"
										variant="outlined"
									>
										{this.props.languages.map((option) => (
											<MenuItem key={option.code} value={option.code}>
												{translate('language_' + option.label)}
											</MenuItem>
										))}
									</TextField>
								)}
								<Box mt={this.props.platform === 'backoffice' ? 0 : 5}>
									<MadeBy />
								</Box>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const selector = formValueSelector('meeting_public_token');
const mapStateToProps = (state) => ({
	token_field: selector(state, 'token'),
	translate: getTranslate(state.localize),
	meeting: state.meetings,
	settings: state.settings,
	config: state.config,
	languages: getLanguages(state.localize).filter((lang) => lang.code !== 'raw'),
	currentLanguage: getActiveLanguage(state.localize),
	login: state.login,
	selectedLanguage: selector(state, 'language'),
});

export default connect(mapStateToProps, { getMeetingPublicAccessAction, getMeetingAccessLoginAction, setActiveLanguage, push })(
	withRouter(MeetingLogin)
);
