import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Main from '../../../Generic/Form/Main';
import { push } from 'connected-react-router';
import { sendRequestCodeAction, sendValidateCodeAction } from '../../actions/usersActions';
import { Collapse, Typography } from '@mui/material';
import { formValueSelector } from 'redux-form';
import { TYPES as FORM_TYPES } from '../../../Generic/FormTypes';

class PasswordValidate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			validate: this.props.recovery_token !== null ? true : false,
			recovery_token: this.props.recovery_token ?? null,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.recovery_token !== this.props.recovery_token) {
			this.setState({
				recovery_token: this.props.recovery_token,
				validate: this.props.recovery_token !== null ? true : false,
			});
		}
	}

	handleRequestCode = (value) => {
		this.setState({
			validate: value,
			recovery_token: null,
		});
	};

	handleConfigRequest = () => {
		const { translate } = this.props;

		const structure = {
			form: 'request_code',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'sm',
			columns: [
				{
					id: 'request-code-column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'request-code-separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'medium_type',
									name: 'medium_type',
									label: translate('user_change_password_type_request_validate'),
									type: FORM_TYPES.SELECT,
									options: [
										{
											label: translate('user_change_password_type_validate_email'),
											value: 'email',
										},
									],
									validate: {
										required: true,
									},
									initialvalue: 'email',
								},
								{
									id: 'medium_value',
									name: 'medium_value',
									label: translate('user_change_password_type_sms_email'),
									type: FORM_TYPES.INPUT,
									validate: {
										required: true,
									},
									helperText: translate('user_change_password_helper_email'),
									initialvalue: this.props.request_code_field === 'email' ? this.props.user.email : '',
								},
							],
						},
					],
				},
			],
			buttons: [
				{
					id: 'request_code_submit_button',
					fullWidth: true,
					label: translate('user_change_password_send_request_validate'),
					grid_layout: { xs: 12 },
					action: () => this.props.sendRequestCodeAction('request_code', () => this.handleRequestCode(true)),
					api_validation: true,
				},
			],
		};

		return structure;
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'code',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'sm',
			columns: [
				{
					id: 'code-column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'code-separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'token',
									name: 'token',
									label: translate('user_change_password_write_validate_code'),
									type: FORM_TYPES.INPUT,
									validate: {
										required: true,
									},
									initialvalue: this.state.recovery_token != null ? this.state.recovery_token : '',
								},
							],
						},
					],
				},
			],
			buttons: [
				{
					id: 'code_submit_button',
					fullWidth: true,
					label: translate('user_change_password_send_validate_code'),
					grid_layout: { xs: 12 },
					action: () =>
						this.props.sendValidateCodeAction(
							'code',
							() => this.props.handleValidateCode(),
							() => this.handleRequestCode(false)
						),
					api_validation: true,
				},
				{
					id: 'code_submit_button',
					fullWidth: true,
					label: translate('generic_button_back'),
					grid_layout: { xs: 12 },
					action: () => this.props.push('/mypages'),
					submit: false,
					color: 'inherit',
				},
			],
		};

		return structure;
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	render() {
		const configuration = this.handleConfig();
		const configurationRequest = this.handleConfigRequest();

		return (
			<div>
				<Collapse in={this.state.recovery_token == null}>
					<Typography align="center" variant="subtitle2">
						{this.props.translate('user_change_password_form_message')}
					</Typography>
					<Main form={configurationRequest.form} formData={configurationRequest} />
				</Collapse>
				<Collapse in={this.state.validate}>
					<Typography align="center" variant="subtitle2">
						{this.props.translate('user_change_password_form_message_code')}
					</Typography>
					<Main form={configuration.form} formData={configuration} />
				</Collapse>
			</div>
		);
	}
}
const selector = formValueSelector('request_code');
const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	request_code_field: selector(state, 'medium_type'),
	user: state.users.whoami,
});

export default connect(mapStateToProps, { sendRequestCodeAction, sendValidateCodeAction, push })(PasswordValidate);
