import React, { Component } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, Box, Card, CardHeader, CardContent, Divider, Collapse, Tooltip } from '@mui/material';
import { getTranslate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			location: props.history.location.pathname,
			hasError: false,
			errorMessage: '',
			type: 'generic',
			showMessage: false,
			copyTooltip: false,
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, errorMessage: error.toString() };
	}

	static getDerivedStateFromProps(props, state) {
		if (state.location !== props.history.location.pathname) {
			return { hasError: false, location: props.history.location.pathname, errorMessage: '', showMessage: false, copyTooltip: false };
		}

		return null;
	}

	render() {
		const { translate } = this.props;

		if (this.state.hasError) {
			return (
				<React.Fragment>
					<Grid container component="main" justifyContent="center" alignContent="center" className={'error-boundary-main-component'}>
						<Grid item xs={12} md={12}>
							<ErrorOutlineIcon color="error" className={'error-boundary-main-icon'} />
							<Typography color={'inherit'} variant="h3">
								{translate('error_boundary_title')}
							</Typography>
							<Typography color={'inherit'} paragraph={true} component="div" variant="body1">
								{translate('error_boundary_description')}
							</Typography>
							<Button
								onClick={() => {
									this.setState({ hasError: false, errorMessage: '', showMessage: false });
								}}
								variant="body2"
								startIcon={<RefreshIcon />}
							>
								{translate('error_boundary_try_again_button')}
							</Button>
							<Button
								onClick={() => {
									this.setState({ hasError: false, errorMessage: '', showMessage: false }, () => {
										this.props.goBack();
									});
								}}
								variant="body2"
								startIcon={<KeyboardReturnIcon />}
							>
								{translate('error_boundary_go_back_button')}
							</Button>
						</Grid>
					</Grid>
					<Box container mt={4} mb={4}>
						<Container maxWidth={'md'}>
							<Card>
								<CardHeader
									title={translate('error_boundary_tech_card_title')}
									action={
										<div>
											<Button
												onClick={() => {
													this.setState({
														showMessage: !this.state.showMessage,
													});
												}}
												variant="body2"
												startIcon={<ExpandMoreIcon />}
											>
												{translate('error_boundary_tech_card_show_message_button')}
											</Button>
											<Tooltip
												open={this.state.copyTooltip}
												disableFocusListener
												disableHoverListener
												disableTouchListener
												title={translate('error_boundary_tech_card_copy_tooltip')}
											>
												<Button
													onClick={() => {
														if (
															typeof navigator.clipboard !== 'undefined' &&
															typeof navigator.clipboard.writeText === 'function'
														) {
															navigator.clipboard.writeText(this.state.errorMessage);
															this.setState({ copyTooltip: true }, () => {
																setTimeout(() => {
																	this.setState({ copyTooltip: false });
																}, 2000);
															});
														}
													}}
													variant="body2"
													startIcon={<FileCopyIcon />}
												>
													{translate('error_boundary_tech_card_copy_button')}
												</Button>
											</Tooltip>
										</div>
									}
								/>
								<Collapse in={this.state.showMessage}>
									<Divider />
									<CardContent>
										<Typography color={'inherit'} variant="body2">
											{this.state.errorMessage}
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
						</Container>
					</Box>
				</React.Fragment>
			);
		}

		return this.props.children;
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, {
	goBack,
})(withRouter(ErrorBoundary));
