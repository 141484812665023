import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Typography, Link, Grid, TextField, Container, MenuItem, Button, FormControl, FormHelperText } from '@mui/material';
import { isMobileSize } from '../../helpers/functions/functions';

import {
	loginBankIDAction,
	checkBankIdProgress,
	bankIDQRGeneratorAction,
	handleSelectClientAction,
	resetBankIDProgress,
} from './actions/loginActions';
import { bankIDQRGeneratorMeetingAction } from '../Meetings/actions/meetingsActions';
import { my_ssn_validations } from '../../helpers/functions/functions';

import { Refresh as RefreshIcon } from '@mui/icons-material';

import { Loading, Form } from '../Generic';

import QRCode from 'qrcode.react';

class LoginBankID extends Component {
	componentDidMount() {
		this.props.resetBankIDProgress();
	}

	state = {
		ssn: '',
		selectedLanguage: this.props.selectedLanguage,
		refreshQrcodeError: false,
	};

	handleSSN = (e) => {
		this.setState({
			ssn: e.target.value,
		});
	};

	handleSubmitButton = (form) => {
		if (typeof this.props.handleSubmitAction !== 'undefined') {
			this.props.handleSubmitAction(form);
		} else {
			this.props.loginBankIDAction(form, this.state.selectedLanguage);
		}
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'bankid',
			title: '',
			subtitle: '',
			helperText: '',
			button_label: translate('generic_button_sign_in'),
			button_loading_label: translate('login_form_loading_button'),
			fields: [
				{
					id: 'bankid_ssn',
					name: 'bankid_ssn',
					label: translate('login_bankid_field_ssn_placeholder'),
					type: 'number',
					disableNumberArrows: true,
					validate: {
						required: true,
						...(this.props.settings.auth.ssnValidation
							? {
									functionValidation: [
										{
											validation: (value) =>
												typeof my_ssn_validations[this.props.settings.auth.ssnValidation] !== 'undefined'
													? my_ssn_validations[this.props.settings.auth.ssnValidation](value)
													: true,
										},
									],
							  }
							: {}),
					},
				},
				{
					id: 'deviceid',
					name: 'deviceid',
					label: '',
					type: 'hidden',
				},
			],
		};

		let initialValues = this.handleDefaultValues(structure);

		return { structure: structure, initialValues: initialValues };
	};

	render() {
		const { translate } = this.props;
		const configuration = this.handleConfig();

		const hasQrcode = this.props.settings.site.supported_login_methods
			.replace(/,\s*$/, '')
			.split(',')
			.map((item) => item.trim())
			.some((item) => item == 'bankid_qrcode');

		return this.props.bankid_progress ? (
			<div>
				<Loading size={50} insideComponent={true} />
				<Typography>{translate('login_bankid_open_app')}</Typography>
			</div>
		) : (
			<React.Fragment>
				{this.props.login.platform === 'backoffice' ? (
					<Container component="main" maxWidth="sm" className="generic-form-main-no-margin">
						<TextField
							fullWidth={true}
							select
							label={this.props.translate('login_select_client_backoffice')}
							value={this.props.login.client !== null && this.props.login.client.id}
							onChange={(value) => {
								this.props.handleSelectClientAction(
									this.props.settings.clients.find((client) => client.id === value.target.value),
									'backoffice',
									'bankID'
								);

								this.setState({
									refreshQrcodeError: false,
								});
							}}
							margin="normal"
							variant="outlined"
						>
							{this.props.settings.clients.map((client) => (
								<MenuItem key={client.id} value={client.id}>
									{client.name}
								</MenuItem>
							))}
						</TextField>
					</Container>
				) : null}

				<Grid container>
					<Grid item xs={12} md={12}>
						<Form
							form={configuration.structure.form}
							formData={configuration.structure}
							initialValues={configuration.initialValues}
							handleSubmit={() => this.handleSubmitButton(configuration.structure.form)}
						/>
					</Grid>
					{this.props.type == 'login' && (
						<Grid item xs={12} md={12}>
							<hr className="generic-hr-text" data-content={translate('login_bankid_or_separator')} />
						</Grid>
					)}
					{hasQrcode && !isMobileSize() && (
						<Grid item xs={12} md={12}>
							<QRCode
								id="bankid_qrcode"
								className="login-bankid-qrcode-disabled"
								value={'bankid:///?autostarttoken=' + this.props.autostarttoken}
							/>
						</Grid>
					)}
					{hasQrcode && !isMobileSize() && (
						<Grid item xs={12} md={12}>
							<FormControl error={this.state.refreshQrcodeError}>
								<Button
									color="secondary"
									variant="contained"
									size="small"
									className="login-bankid-button-refresh"
									onClick={(e) => {
										if (this.props.client !== null && this.props.client.id !== 0) {
											if (this.props.type == 'login_meeting') {
												this.props.bankIDQRGeneratorMeetingAction(this.state.selectedLanguage);
											} else {
												this.props.bankIDQRGeneratorAction(this.state.selectedLanguage);
											}
											this.setState({
												refreshQrcodeError: false,
											});
										} else {
											this.setState({
												refreshQrcodeError: true,
											});
										}
									}}
								>
									{translate('login_bankid_button_refresh')}
									<RefreshIcon />
								</Button>
								{this.state.refreshQrcodeError && (
									<FormHelperText className="welcome-main" id="component-error-text-refresh-button">
										{translate('login_bankid_button_refresh_error')}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{this.props.type == 'login' && (
						<Grid item xs={12} md={12}>
							<Link
								href="#"
								variant="body2"
								onClick={() => {
									this.props.handleSelectClientAction(null, null);
								}}
							>
								&crarr; {translate('login_form_back_to_client_selection')}
							</Link>
						</Grid>
					)}
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	bankid_progress: state.login.bankid_progress,
	autostarttoken: state.login.autostarttoken,
	login: state.login,
	translate: getTranslate(state.localize),
	settings: state.settings,
	client: state.login.client,
});

export default connect(mapStateToProps, {
	loginBankIDAction,
	checkBankIdProgress,
	bankIDQRGeneratorAction,
	handleSelectClientAction,
	bankIDQRGeneratorMeetingAction,
	resetBankIDProgress,
})(LoginBankID);
