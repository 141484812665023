import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getTranslate } from 'react-localize-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function PromptDialog(props) {
	const { translate } = props;

	return (
		<Dialog open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
			<DialogTitle>
				{typeof props.prompt_dialog_title !== 'undefined'
					? translate(props.prompt_dialog_title)
					: translate(props.prompt.prompt_dialog_title)}
			</DialogTitle>

			<DialogContent>
				<DialogContentText gutterBottom>
					{typeof props.prompt_dialog_description !== 'undefined'
						? translate(props.prompt_dialog_description)
						: translate(props.prompt.prompt_dialog_description)}
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button onClick={props.cancel} variant="contained" color="secondary">
					{props.back_label ? translate(props.back_label) : translate('generic_button_cancel')}
				</Button>

				{props.prompt.allow_resume && (
					<Button onClick={props.resume} variant="contained" color="primary">
						{props.next_label ? translate(props.next_label) : translate('generic_button_continue')}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

const { bool, func } = PropTypes;

PromptDialog.defaultProps = {
	open: false,
};

PromptDialog.propTypes = {
	open: bool,
	onClose: func,
	cancel: func,
	resume: func,
};

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	prompt: state.prompt,
});

export default connect(mapStateToProps)(PromptDialog);
