import PlaceholderIMG from '../images/placeholder.svg';

const login = (theme) => {
	return {
		'.login-main': {
			textAlign: 'center',
			height: '100%',

			[theme.breakpoints.down('md')]: {
				height: 'auto',
			},
		},
		'.login-content-left-image': {
			backgroundImage: (props) =>
				typeof props.settings.site !== 'undefined' && props.settings.site.files.login_background
					? `url(${props.config.apihost + '/configuration/files/' + props.settings.site.files.login_background.uuid})`
					: `url(${PlaceholderIMG})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
		},
		'.login-content-left-title': {
			padding: 15,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		'.login-content-right-main': {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh',
		},
		'.login-content-right-content': {
			margin: theme.spacing(8, 3),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: theme.spacing(8),
			marginBottom: theme.spacing(2),
		},
		'.login-content-right-content-backoffice': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginBottom: theme.spacing(2),
		},
		'.login-content-right-content-tabs': {
			backgroundColor: theme.palette.background.paper,
			marginTop: theme.spacing(4),
			width: '100%',
			maxWidth: '500px !important',
			marginTop: theme.spacing(4),
			marginBottom: '0px',
			marginRight: '0px',
			marginLeft: '0px',
		},
		'.login-content-right-content-tab-form': {
			width: '100%',
			marginTop: theme.spacing(1),
		},
		'.login-content-right-content-tab-form-submit': {
			margin: theme.spacing(3, 0, 2),
		},
		'.login-content-right-content-avatar': {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.primary.main,
		},
		'.login-content-right-content-buttons': {
			marginTop: theme.spacing(8),
			maxHeight: '70vh',
			overflow: 'auto',
		},
		'.login-content-right-button': {
			margin: theme.spacing(1),
			width: '100%',
			justifyContent: 'space-between',
			minHeight: '50px',
		},
		'.login-content-right-button-clients': {
			margin: theme.spacing(1),
			width: '70%',
			justifyContent: 'space-between',
			minHeight: '50px',
			textAlign: 'initial',
			fontSize: '12px'
		},
		'.login-content-right-button-logout': {
			margin: theme.spacing(1),
			width: '100%',
			justifyContent: 'space-between',
			minHeight: '35px',
		},
		'.login-content-right-footer': {
			padding: theme.spacing(2),
			marginTop: 'auto',
		},
		'.login-bankid-qrcode-disabled': {
			filter: 'blur(5px)',
			opacity: '0.5',
		},
		'.login-bankid-button-refresh': {
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
		'.login-bankid-alert-infobox': {
			marginBottom: theme.spacing(2),
		},
		'.login-backoffice-language-selector': {
			color: 'white',
			mixBlendMode: 'difference',
			borderColor: 'white',
		},
		'.login-backoffice-language-selector > svg': {
			color: 'white',
			mixBlendMode: 'difference',
			borderColor: 'white',
		},
		'.login-backoffice-language-selector > fieldset': {
			color: 'white',
			mixBlendMode: 'difference',
			borderColor: 'white !important',
		},
		'.login-register-content-button': {
			margin: theme.spacing(1, 0, 6),
		},
    '.login-tab-item-selected': {
      backgroundColor: theme.palette.secondary.light
    }
	};
};

export default login;
