import React from 'react';
import { Autocomplete } from '@mui/material';
import { TextField, FormHelperText, MenuItem } from '@mui/material';

function RenderAutocomplete({ input, meta: { invalid, touched, error, active }, ...custom }) {
	return (
        <div>
			<Autocomplete
				className={custom.inputdata.validate && touched && invalid ? 'generic-form-autocomplete-error' : ''}
				multiple={custom.inputdata.multiple}
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				options={custom.inputdata.options}
				groupBy={custom.inputdata.groupby}
				getOptionLabel={(option) => option.label}
				blurOnSelect={true}
				defaultValue={
					custom.inputdata.multiple
						? []
						: {
								label: input.value,
								value: input.value,
						  }
				}
				isOptionEqualToValue={(option, value) => {
					return option.value === value.value;
				}}
				onChange={(event, option) => {
					input.onChange(option.value);
				}}
				filterSelectedOptions={true}
				renderInput={(params) => {
					return (
						<TextField
							label={custom.inputdata.label}
							onChange={(event, value) => {
								input.onChange(value);
								if (typeof custom.inputdata.onChange !== 'undefined') {
									custom.inputdata.onChange(value);
								}
							}}
							fullWidth
							variant="outlined"
							disabled={custom.inputdata.readonly}
							value={params.inputProps.value}
              error={touched && invalid && !active}
              helperText={touched && !active && error}
							{...custom}
							{...params}
						/>
					);
				}}
			/>
			<FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"> {custom.inputdata.helperText}</FormHelperText>
		</div>
    );
}

export default RenderAutocomplete;
