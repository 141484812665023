import MeetingLoginBackground from '../../assets/images/meetings_login_background.jpg';
import MeetingLoginBackgroundMobile from '../../assets/images/meetings_login_background_mobile.jpg';


const meetings = (theme) => {
	return {
		
		// '.MuiLink-button': {
		// 	border: 'unset',
		// 	padding: '6px 16px',
		// 	backgroundColor: '#e0e0e0',
		// },
		'.meeting-public-main-component': {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh',
			backgroundColor: theme.palette.common.white,
		},
		'.meeting-public-title': {
			color: theme.palette.primary,
		},
		'.meeting-subtitle': {
			// color: theme.palette.primary,
		},
		'.meeting-description': {
			// color: theme.palette.primary,
		},
		'.meeting-form-margin': {
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(9),
		},
		'.meeting-form-external-button': {
			textDecorationLine: 'underline',
		},
		'.meeting-form-colapse-copy_button': {
			width: '100%',
		},
		'.meeting-login-tabs-content': {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
		},
		'.meeting-login-content-right-content-tabs': {
			backgroundColor: theme.palette.background.paper,
			marginTop: theme.spacing(1),
			maxWidth: '500px !important',
			marginBottom: '0px',
		},
		'.meeting-login-content-left-image': {
			backgroundImage: (props) =>
				typeof props.settings.site !== 'undefined' && props.settings.site.files.meetings_image
					? `url(${props.config.apihost + '/configuration/files/' + props.settings.site.files.meetings_image.uuid})`
					: `url(${MeetingLoginBackground})`,
			[theme.breakpoints.down('xl')]: {
				backgroundImage: (props) =>
				typeof props.settings.site !== 'undefined' && props.settings.site.files.meetings_image_mobile
					? `url(${props.config.apihost + '/configuration/files/' + props.settings.site.files.meetings_image_mobile.uuid})`
					: `url(${MeetingLoginBackgroundMobile})`,
				minHeight: '30%',
			},
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
		},
		'.meeting-login-main': {
			textAlign: 'center',
			height: '100%',
		},
		'.meeting-summary-chat': {
			borderColor: theme.palette.grey[300],
			marginTop: theme.spacing(1),
		},
		'.meeting-summary-selector': {
			marginBottom: theme.spacing(4),
		},
		'.meeting-summary-overview-align': {
			textAlign: 'justify',
		},
		'.meeting-precall-button': {
			marginTop: theme.spacing(3),
		},
		'.meeting-modal-padding': {
			padding: theme.spacing(2),
		},
		'.meeting-modal-loading-center': {
			justifyContent: 'center',
			display: 'flex',
		},
		'@keyframes blink': {
			'0%': {
				opacity: '100%',
			},
			'50%': {
				opacity: '50%',
			},
			'100%': {
				opacity: '10%',
			},
		},
		'.meeting-waiting-join-button': {
			MozTransition: 'all 1s ease-in-out',
			WebkitTransition: 'all 1s ease-in-out',
			OTransition: 'all 1s ease-in-out',
			MsTransition: 'all 1s ease-in-out',
			transition: 'all 1s ease-in-out',
			MozAnimation: 'blink normal 2s infinite ease-in-out',
			/* Firefox */
			WebkitAnimation: 'blink normal 2s infinite ease-in-out',
			/* Webkit */
			MsAnimation: 'blink normal 2s infinite ease-in-out',
			/* IE */
			animation: 'blink normal 2s infinite ease-in-out',
			/* Opera */
		},
		'.meeting-form-add-external-alert': {
			marginBottom: theme.spacing(1.5)
		},
		'.meetings-chipstatus-pending': {
			backgroundColor: '#F4D58D',
		},
		'.meetings-chipstatus-active': {
			color: 'white',
			backgroundColor: '#00A99D',
		},
		'.meetings-chipstatus-closed': {
			color: 'white',
			backgroundColor: '#6E7A8C',
		},
		'.meetings-chipstatus-archived': {
			color: 'white',
			backgroundColor: theme.palette.grey[500],
		},
	};
};

export default meetings;
